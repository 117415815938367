export const SilkRefinanceProductTypes = {
  AK: 77,
  AL: 81,
  AR: 82,
  AZ: 80,
  CA: 90,
  CO: 76,
  CT: 77,
  DC: 80,
  DE: 80,
  FL: 76,
  GA: 80,
  HI: 78,
  IA: 80,
  ID: 77,
  IL: 80,
  IN: 80,
  KS: 80,
  KY: 80,
  LA: 77,
  MA: 80,
  MD: 80,
  ME: 80,
  MI: 80,
  MN: 80,
  MO: 80,
  MS: 80,
  MT: 80,
  NC: 76,
  ND: 80,
  NE: 80,
  NH: 80,
  NJ: 76,
  NM: 77,
  NV: 80,
  NY: 76,
  OH: 80,
  OK: 77,
  OR: 86,
  PA: 76,
  RI: 80,
  SC: 80,
  SD: 81,
  TN: 80,
  TX: 88,
  UT: 80,
  VA: 80,
  VT: 80,
  WA: 77,
  WI: 80,
  WV: 80,
  WY: 79,
}

export const SilkPurchaseProductTypes = {
  AK: 71,
  AL: 71,
  AR: 71,
  AZ: 16,
  CA: 93,
  CO: 16,
  CT: 71,
  DC: 16,
  DE: 16,
  FL: 16,
  GA: 16,
  HI: 71,
  IA: 16,
  ID: 71,
  IL: 16,
  IN: 16,
  KS: 16,
  KY: 16,
  LA: 71,
  MA: 16,
  MD: 16,
  ME: 16,
  MI: 16,
  MN: 16,
  MO: 16,
  MS: 16,
  MT: 16,
  NC: 16,
  ND: 16,
  NE: 16,
  NH: 16,
  NJ: 16,
  NM: 71,
  NV: 16,
  NY: 16,
  OH: 16,
  OK: 71,
  OR: 71,
  PA: 16,
  RI: 16,
  SC: 16,
  SD: 71,
  TN: 16,
  TX: 71,
  UT: 73,
  VA: 16,
  VT: 16,
  WA: 71,
  WI: 16,
  WV: 16,
  WY: 71,
}
