import get from 'lodash/get'
import { SECTIONS, LoanEstimateParameter } from './types'
import { FieldMap, derivedCalculations } from './constants'
import { sanitizeName } from './sanitizeName'
import { LoanPurpose } from '@mortgage-pos/types'

export const calculatePrepaidTotal = ({
  loanEstimateRecord,
}: LoanEstimateParameter) => {
  const filteredKeys = filterPrepaidKeys({
    loanPurpose: loanEstimateRecord.rate.loanPurpose,
  })
  const total = Object.entries(filteredKeys).reduce((acc, curr) => {
    const value = calculateValue({
      loanEstimateRecord,
      curr,
    })
    return (acc +=
      value && typeof value === 'number' ? Number(value.toFixed(2)) : 0)
  }, 0)
  return total
}

export const calculateEstClosingCosts = ({
  loanEstimateRecord,
}: LoanEstimateParameter) => {
  const total = loanEstimateRecord.rate.fees.reduce(
    (acc, curr) => (acc += curr.amount),
    0
  )
  return total
}

export const filterPrepaidKeys = ({ loanPurpose }) => {
  return FieldMap[SECTIONS.Prepaid]
}

export const getValueFromFieldMap = (loanEstimateRecord, val) =>
  get(loanEstimateRecord, val, '')

export const calculateValue = ({ loanEstimateRecord, curr: [key, value] }) => {
  const record = Array.isArray(value)
    ? value.map((m) => getValueFromFieldMap(loanEstimateRecord, m))
    : getValueFromFieldMap(loanEstimateRecord, String(value))
  if (!record) return null
  if (derivedCalculations[String(key)]) {
    return derivedCalculations[String(key)](record)
  }
  return record
}

export const getOriginationFee = ({
  loanEstimateRecord,
}: LoanEstimateParameter): number => {
  const originationFeeLocation = loanEstimateRecord.rate.fees.find(
    (fee) => sanitizeName(fee.description) === 'Loan origination fee'
  )
  if (!originationFeeLocation) return 0
  return Math.round(originationFeeLocation.amount)
}

export const getLenderCreditValue = ({
  loanEstimateRecord,
}: LoanEstimateParameter): number =>
  calculateValue({
    loanEstimateRecord,
    curr: ['Lender Credit', "rate.loanEstimateSections['J'].fees"],
  })

export const getSageClosingCreditOfferValue = ({
  loanEstimateRecord,
}: LoanEstimateParameter): number =>
  calculateValue({
    loanEstimateRecord,
    curr: ['Sage Closing Credit Offer', "rate.loanEstimateSections['J'].fees"],
  })

export const calculateOtherFeesMinusOrigination = ({
  loanEstimateRecord,
}: LoanEstimateParameter) =>
  loanEstimateRecord.rate.fees.reduce((acc, curr) => {
    if (sanitizeName(curr.description) === 'Loan origination fee') return acc
    acc += Math.round(curr.amount)
    return acc
  }, 0)

export const calculateEstimatedLoanCosts = ({
  loanEstimateRecord,
}: LoanEstimateParameter): number => {
  const originationFee = Math.round(getOriginationFee({ loanEstimateRecord }))
  // negative value
  const lenderCreditValue = Math.round(
    getLenderCreditValue({ loanEstimateRecord })
  )
  const sageClosingCreditOffer = Math.round(
    getSageClosingCreditOfferValue({
      loanEstimateRecord,
    })
  )
  const otherFeesTotal = Math.round(
    calculateOtherFeesMinusOrigination({
      loanEstimateRecord,
    })
  )

  const feesAndLenderCreditMinusOriginationFee =
    otherFeesTotal + lenderCreditValue

  if (feesAndLenderCreditMinusOriginationFee > 0) {
    return (
      feesAndLenderCreditMinusOriginationFee +
      originationFee +
      sageClosingCreditOffer
    )
  }

  return originationFee
}

export const calculateSummaryValues = ({
  loanEstimateRecord,
  key,
  value,
}): number => {
  switch (key) {
    case 'Total Est. Payoffs and Payments': {
      const lineItem = calculateTotalEstimatedPayoffsAndPayments({
        loanEstimateRecord,
      })
      return lineItem ? Number(lineItem.toFixed(2)) : 0
    }
    case 'Loan Amount': {
      const loanAmount = loanEstimateRecord.rate.loanAmount * -1
      return loanAmount ? Number(loanAmount.toFixed(2)) : 0
    }
    default: {
      return value ? value : 0
    }
  }
}

export const calculateTotalEstimatedPayoffsAndPayments = ({
  loanEstimateRecord,
}: LoanEstimateParameter): number => {
  const estimatedLoanCostsMinusOrigination = Math.round(
    calculateOtherFeesMinusOrigination({ loanEstimateRecord })
  )
  const totalEstimatedPrepaid = Math.round(
    calculatePrepaidTotal({ loanEstimateRecord })
  )
  const originationFee = Math.round(getOriginationFee({ loanEstimateRecord }))
  // lender credit is a negative number
  const lenderCredit = Math.round(getLenderCreditValue({ loanEstimateRecord }))
  const sageClosingCreditOffer = Math.round(
    getSageClosingCreditOfferValue({
      loanEstimateRecord,
    })
  )

  const preTotalEstimatedPayoffsAndPayments =
    estimatedLoanCostsMinusOrigination + totalEstimatedPrepaid + lenderCredit

  if (preTotalEstimatedPayoffsAndPayments > 0) {
    return Math.round(
      preTotalEstimatedPayoffsAndPayments +
        originationFee +
        sageClosingCreditOffer
    )
  }
  return Math.round(originationFee)
}

export const calculatePrincipalCurtailment = ({
  loanEstimateRecord,
}: LoanEstimateParameter) => {
  const estimatedLoanCostsMinusOrigination = Math.round(
    calculateOtherFeesMinusOrigination({ loanEstimateRecord })
  )
  const totalEstimatedPrepaid = Math.round(
    calculatePrepaidTotal({ loanEstimateRecord })
  )
  // lender credit is a negative number
  const lenderCredit = getLenderCreditValue({ loanEstimateRecord })

  if (
    estimatedLoanCostsMinusOrigination + totalEstimatedPrepaid + lenderCredit <
    0
  ) {
    return Math.round(
      Math.abs(
        estimatedLoanCostsMinusOrigination +
          totalEstimatedPrepaid +
          lenderCredit
      )
    )
  }
  return undefined
}

export const calculateCashToFromBorrower = ({ loanEstimateRecord }) => {
  const principalCurtailment = calculatePrincipalCurtailment({
    loanEstimateRecord,
  })
  const principalCurtailmentKey = 'Principal Curtailment'
  const downPayment = loanEstimateRecord.rate.propertyDownPayment
  const downPaymentKey = 'Down Payment'

  const fields = {
    ...FieldMap[SECTIONS.SummaryOfCC],
    ...(principalCurtailment && { 'Principal Curtailment': null }),
    ...(loanEstimateRecord.rate.loanPurpose === 'Purchase' && {
      'Down Payment': null,
    }),
  }
  const total = Object.entries(fields).reduce((acc, curr) => {
    const key = String(curr[0])
    const value =
      key === principalCurtailmentKey
        ? 0
        : key === downPaymentKey
        ? downPayment
        : calculateValue({ loanEstimateRecord, curr })

    const summaryValue = calculateSummaryValues({
      loanEstimateRecord,
      key,
      value,
    })
    return (acc += summaryValue)
  }, 0)
  return total
}
