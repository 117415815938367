import { gql } from 'apollo-boost'
import groupBy from 'lodash/groupBy'
import { history } from '@mortgage-pos/ui/history'
import { StoreModel } from './index'
import { AxiosResponse } from 'axios'
import rollbar from '@mortgage-pos/ui/services/rollbar'
import { graphQL } from '@mortgage-pos/ui/services/http'
import { environment } from '@mortgage-pos/ui/env'
import { getLandingUrl } from '@mortgage-pos/ui/utils/landingUrl'
import { getReferrerUrl } from '@mortgage-pos/ui/utils/referrerUrl'
import { variation } from '@mortgage-pos/ui/services/featureFlags'
import { getGraphQLString, redirectTo } from '@mortgage-pos/utils'
import { FEATURE_FLAG_TYPES } from '@mortgage-pos/data'
import { CustomError } from '@mortgage-pos/error-handling'
import ApplicationClient from '@mortgage-pos/ui/services/ApplicationClient'
import {
  isQuestionVisible,
  isVisibleIfConstructor,
} from '@mortgage-pos/ui/utils/visibleIf'
import incense from '@mortgage-pos/ui/incense'

import {
  action,
  Action,
  actionOn,
  ActionOn,
  Computed,
  computed,
  Thunk,
  thunk,
  thunkOn,
  ThunkOn,
} from 'easy-peasy'

import {
  Page,
  Status,
  ErrorPage,
  OtherPage,
  LoanPurpose,
  QuestionSet,
  SuccessPage,
  RateSelectPage,
  QuestionSetSlug,
  QuestionnaireType,
  SectionStatusCode,
  RedirectInterstitial,
  Routes,
  MessageTypes,
} from '@mortgage-pos/types'
import { redirectToOtherQuestionSet } from '@mortgage-pos/ui/utils/navigation'
import newRelic from '@mortgage-pos/ui/services/newRelic'
import { enableMapSet } from 'immer'

// This helps [Unit Tests] to add data to the redux store
enableMapSet()

export interface InitializeQuestionnairePayload {
  questionSet: QuestionSet
}

export interface StatusesMap {
  lock: Status
  rates: Status
  noRates: Status
  completed: Status
  touched?: Status
}

export interface QuestionnaireModel {
  // State
  slug: string
  active: Page
  pages: Page[]
  fBLeadId: string
  pageIndex: number
  finished: boolean
  landingUrl: string
  referrerUrl: string
  isSubmitting: boolean
  bankrateLeadId: string
  directMailerOfferCode: string
  errorPages: ErrorPage[]
  ratesPage: RateSelectPage
  successPages: SuccessPage[]
  questionnaireType: QuestionnaireType
  redirectInterstitial: RedirectInterstitial
  searchString: string
  // Computed
  isStart: Computed<QuestionnaireModel, boolean, StoreModel>
  shouldShowRates: Computed<QuestionnaireModel, boolean, StoreModel>
  isSplitFlow: Computed<QuestionnaireModel, boolean, StoreModel>
  isPrePricingFlow: Computed<QuestionnaireModel, boolean, StoreModel>
  isApplyFlow: Computed<QuestionnaireModel, boolean, StoreModel>
  isPhoneFlow: Computed<QuestionnaireModel, boolean, StoreModel>
  isBrProFlow: Computed<QuestionnaireModel, boolean, StoreModel>
  isFullFlow: Computed<QuestionnaireModel, boolean, StoreModel>
  isMiniFlow: Computed<QuestionnaireModel, boolean, StoreModel>
  statusesMap: Computed<QuestionnaireModel, StatusesMap, StoreModel>
  // Actions
  setSlug: Action<QuestionnaireModel, string>
  setPages: Action<QuestionnaireModel, Page[]>
  setActive: Action<QuestionnaireModel, Page>
  setFBLeadId: Action<QuestionnaireModel, string>
  setDirectMailerOfferCode: Action<QuestionnaireModel, string>
  setPageIndex: Action<QuestionnaireModel, number>
  setFinished: Action<QuestionnaireModel, boolean>
  setRatesPage: Action<QuestionnaireModel, RateSelectPage>
  setErrorPages: Action<QuestionnaireModel, ErrorPage[]>
  setSuccessPages: Action<QuestionnaireModel, SuccessPage[]>
  setIsSubmitting: Action<QuestionnaireModel, boolean>
  setBankrateLeadId: Action<QuestionnaireModel, string>
  setRedirectInterstitial: Action<QuestionnaireModel, RedirectInterstitial>
  setSearchString: Action<QuestionnaireModel, string>
  initialize: Thunk<
    QuestionnaireModel,
    InitializeQuestionnairePayload,
    null,
    StoreModel
  >
  // actionOn
  onSetPageIndex: ActionOn<QuestionnaireModel, StoreModel>
  onSetSlug: ActionOn<QuestionnaireModel, StoreModel>
  // Thunks
  next: Thunk<QuestionnaireModel, null, object, StoreModel>
  submit: Thunk<QuestionnaireModel, null, object, StoreModel>
  back: Thunk<QuestionnaireModel, object, object, StoreModel>
  answer: Thunk<QuestionnaireModel, object, object, StoreModel>
  getQuestionnaire: Thunk<QuestionnaireModel, string, object, StoreModel>
  getAdjacentPageIndex: Thunk<QuestionnaireModel, number, object, StoreModel>
  // ThunkOn
  handlePhoneAppRedirect: ThunkOn<QuestionnaireModel, null, StoreModel>
  handleInitialPage: ThunkOn<QuestionnaireModel, null, StoreModel>
}

/**
 * This module is a function because we need to instantiate different Questionnaire class instance for every run.
 * It helps to create a blank slate every time a store instance gets created in a test.
 */
const questionnaire = (): QuestionnaireModel => {
  return {
    questionnaireType: QuestionnaireType.FULL1003, // Default to prod flow

    slug: null,

    active: null,

    pages: [],

    errorPages: [],

    successPages: null,

    ratesPage: null,

    pageIndex: 0,

    redirectInterstitial: {
      component: 'RedirectInterstitial',
    },

    finished: false,

    bankrateLeadId: null,

    directMailerOfferCode: null,

    isSubmitting: false,

    fBLeadId: localStorage.getItem('fBLeadId'),

    referrerUrl: getReferrerUrl(),

    landingUrl: getLandingUrl(),

    searchString: null,

    isStart: computed(
      [
        (state) => state.active,
        (state) => state.pages,
        (state, storeState) => storeState.answers.mergedAnswers,
        (state, storeState) => storeState.visibleIfConditions,
      ],
      (active, pages, mergedAnswers, visibleIfConditions) => {
        if (!active) return false

        const firstVisiblePage = pages?.find((page) => {
          const visibleIfCondition = page.visibleIfConstructor?.[0]

          return (
            !visibleIfCondition ||
            isQuestionVisible(
              visibleIfCondition,
              mergedAnswers,
              visibleIfConditions
            )
          )
        })

        return active.name === firstVisiblePage?.name
      }
    ),

    shouldShowRates: computed(
      [
        (state) => state.bankrateLeadId,
        (state) => state.finished,
        (_, storeState) => storeState.answers.answers,
        (_, storeState) => storeState.propertyInfo.avmEstimatedValue,
        (_, storeState) => storeState.application.applicationId,
      ],
      (bankrateLeadId, finished, answers, avmEstimatedValue, applicationId) => {
        const { loanPurpose } = answers

        let requiredQuestions = ['zipCode']

        if (loanPurpose === LoanPurpose.Refinance) {
          requiredQuestions.push('remainingBalance')
          if (!avmEstimatedValue) {
            requiredQuestions.push('estimatedValue')
          }
        }

        //If estimated value is not populated from initial answers because we don't have a bankrateLeadId,
        //we need to require estimatedValue from the user to get rates
        if (null == bankrateLeadId) {
          requiredQuestions = requiredQuestions.concat([
            'propertyType',
            'propertyUse',
          ])
        }

        const questionsAnswered = Object.keys(answers)

        const missingQuestions = []
        const wereAllRequiredQuestionsAnswered = requiredQuestions.every(
          (questionName) => {
            const questionWasAnswered = questionsAnswered.includes(questionName)
            if (!questionWasAnswered) {
              missingQuestions.push(questionName)
            }
            return questionWasAnswered
          }
        )

        if (finished && !wereAllRequiredQuestionsAnswered && applicationId) {
          rollbar.error('Missing required questions to show pricing.', {
            missingQuestions,
            applicationId,
          })
        }

        return wereAllRequiredQuestionsAnswered
      }
    ),

    isSplitFlow: computed([(state) => state.slug], (slug) => {
      // using a string here instead of the enum to account for personal/duplicated flows
      return isQuestionSet(/pre-pricing|apply/, slug)
    }),

    isPrePricingFlow: computed([(state) => state.slug], (slug) => {
      return isQuestionSet(/pre-pricing/, slug)
    }),

    isApplyFlow: computed([(state) => state.slug], (slug) => {
      return isQuestionSet(/apply/, slug)
    }),

    isPhoneFlow: computed([(state) => state.slug], (slug) => {
      return isQuestionSet(/phone/, slug)
    }),

    isBrProFlow: computed([(state) => state.slug], (slug) => {
      return isQuestionSet(/purchase-fastpass/, slug)
    }),

    isFullFlow: computed([(state) => state.slug], (slug) => {
      return isQuestionSet(/^full-.*/, slug)
    }),

    isMiniFlow: computed([(state) => state.slug], (slug) => {
      const miniFlowParam = new URLSearchParams(window.location.search).get(
        'mini-flow'
      )
      return miniFlowParam === 'true'
    }),

    // This differentiates statuses based on whether the user is in the
    // single-application Alfie flow vs the split flows for `Pre-Pricing`
    // and `Apply`
    statusesMap: computed((state) => {
      // Is `Pre-Pricing`/`Apply` version of the application
      if (state.isSplitFlow) {
        const isPrePricingFlow = state.slug.includes('pre-pricing')

        const touchedStatus = isPrePricingFlow
          ? Status.PrePricingFlowTouched
          : Status.ApplyFlowTouched

        const completedStatus = isPrePricingFlow
          ? Status.PrePricingFlowCompleted
          : Status.ApplyFlowCompleted

        return {
          lock: Status.PrePricingFlowCompleted,
          rates: Status.PrePricingRatesDisplayed,
          noRates: Status.PrePricingNoRatesDisplayed,
          touched: touchedStatus,
          completed: completedStatus,
        }
      }

      if (state.isPhoneFlow) {
        return {
          lock: Status.LockRequested,
          rates: Status.QRatesDisplayed,
          noRates: Status.QNoRatesDisplayed,
          touched: Status.PhoneFlowTouched,
          completed: Status.PhoneFlowCompleted,
        }
      }

      return {
        lock: Status.LockRequested,
        rates: Status.QRatesDisplayed,
        noRates: Status.QNoRatesDisplayed,
        completed: Status.QFormCompleted,
      }
    }),

    onSetSlug: actionOn(
      (actions) => actions.setSlug,
      (state, target) => {
        const ntlSlug = QuestionSetSlug.NTL
        const isNtl = target.payload?.toLowerCase()?.includes(ntlSlug)

        if (isNtl || state.isSplitFlow) {
          state.questionnaireType = QuestionnaireType.NTL
        } else {
          state.questionnaireType = QuestionnaireType.FULL1003
        }
      }
    ),

    setPages: action((state, payload) => {
      state.pages = payload
    }),

    setErrorPages: action((state, payload) => {
      state.errorPages = payload
    }),

    setSuccessPages: action((state, payload) => {
      state.successPages = payload
    }),

    setRatesPage: action((state, payload) => {
      state.ratesPage = payload
    }),

    setRedirectInterstitial: action((state, payload) => {
      state.redirectInterstitial = payload
    }),

    setSlug: action((state, slug) => {
      state.slug = slug
    }),

    setSearchString: action((state, payload) => {
      state.searchString = payload
    }),

    setActive: action((state, activePage) => {
      state.active = activePage
    }),

    setPageIndex: action((state, pageIndex) => {
      state.pageIndex = pageIndex
    }),

    setFinished: action((state, isFinished) => {
      state.finished = isFinished
    }),

    setBankrateLeadId: action((state, payload) => {
      state.bankrateLeadId = payload
    }),

    setIsSubmitting: action((state, payload) => {
      state.isSubmitting = payload
    }),

    setFBLeadId: action((state, payload) => {
      try {
        localStorage.setItem('fBLeadId', payload)
        state.fBLeadId = payload
      } catch (e) {
        return
      }
    }),
    setDirectMailerOfferCode: action((state, payload) => {
      state.directMailerOfferCode = payload
    }),

    onSetPageIndex: actionOn(
      (actions, storeActions) => actions.setPageIndex,
      (state, target) => {
        state.active = state.pages[target.payload]
      }
    ),

    getQuestionnaire: thunk(
      async (
        { setSlug, initialize },
        questionSetSlug = QuestionSetSlug.NTL,
        { getStoreActions, getState, getStoreState }
      ) => {
        const { bankrateLeadId, directMailerOfferCode } = getState()
        const { applicationGuid } = getStoreState().application
        const { tracking, pageSections, answers } = getStoreActions()
        const { getLicensedStates } = getStoreActions().licensedStates

        await getLicensedStates()

        let questionnaireResponse: AxiosResponse

        const isPhoneFlow = isQuestionSet(/phone/, questionSetSlug)
        const isPhoneFlowEnabled = await variation(
          FEATURE_FLAG_TYPES.PHONE_FLOW_ENABLED
        )

        let appGuid = applicationGuid

        if (isPhoneFlow && isPhoneFlowEnabled) {
          // We don't want to hydrate answers for the phone flow
          appGuid = null
        }

        try {
          questionnaireResponse = await requestQuestionnaire(
            questionSetSlug,
            bankrateLeadId,
            appGuid,
            directMailerOfferCode
          )

          if (
            environment.productName !== 'sage-home-loans' &&
            questionnaireResponse.data.errors?.length > 0
          ) {
            throw new Error(questionnaireResponse.data.errors[0].message)
          }
        } catch (error) {
          const errorObject = new CustomError(
            'GetQuestionnaireError',
            'Failed to fetch questionnaire from Storyblok'
          )

          const errorMetadata = {
            questionSetSlug,
            bankrateLeadId,
            applicationGuid,
            sensitive: { error, questionnaireResponse },
          }

          rollbar.error(errorObject, errorMetadata)

          newRelic.increment('get_questionnaire.fail', [
            { key: 'from_bankrate', value: (!!bankrateLeadId).toString() },
          ])

          throw error
        }

        let questionSet: QuestionSet
        let mergedAnswers

        try {
          setSlug(questionSetSlug)

          const leadAnswers = questionnaireResponse.data.data.getAnswers
          answers.setLeadAnswers(leadAnswers)

          questionSet = questionnaireResponse.data.data.getQuestionnaire
          mergedAnswers = getStoreState().answers?.mergedAnswers

          tracking.setFlowID(questionSet?.flowId)
        } catch (error) {
          const errorObject = new CustomError(
            'GetQuestionnaireError',
            'Failed to prefill, set slug and designate loanPurpose'
          )

          const errorMetadata = {
            questionSetSlug,
            bankrateLeadId,
            applicationGuid,
            sensitive: { error, mergedAnswers, questionnaireResponse },
          }

          rollbar.error(errorObject, errorMetadata)

          newRelic.increment('get_questionnaire.fail', [
            { key: 'from_bankrate', value: (!!bankrateLeadId).toString() },
          ])

          throw error
        }

        // OJO Demo only
        if (getStoreState().questionnaire.slug === 'compare-ntl-demo') {
          getStoreActions().leComparison.setIsDemoFlow(true)

          // Simulate purchase for pre-approval demo
          getStoreActions().answers.setAnswers({
            loanPurpose: LoanPurpose.Purchase,
            propertyPurchasePrice: 660000,
            creditScore: 740,
          })
        }

        try {
          await initialize({
            questionSet,
          })
        } catch (error) {
          const errorObject = new CustomError(
            'GetQuestionnaireError',
            'Failed to initialize'
          )

          const errorMetadata = {
            questionSetSlug,
            bankrateLeadId,
            applicationGuid,
            sensitive: { error, mergedAnswers, questionnaireResponse },
          }

          rollbar.error(errorObject, errorMetadata)

          newRelic.increment('get_questionnaire.fail', [
            { key: 'from_bankrate', value: (!!bankrateLeadId).toString() },
          ])

          throw error
        }
      }
    ),

    initialize: thunk(
      async (actions, payload, { getStoreState, getStoreActions }) => {
        const { isMiniFlow } = getStoreState().questionnaire
        if (isMiniFlow) {
          window.parent.postMessage(
            {
              name: MessageTypes.MiniFlowTaskStarted,
            },
            '*'
          )
        }
        const { checkSplitFlowSessionLength } = getStoreActions().noTalkLock
        try {
          await checkSplitFlowSessionLength()
        } catch (e) {
          incense(e)
            .details({
              name: 'questionnaireInitialize',
              message: 'Error checking single session logic',
            })
            .sensitive({
              payload,
            })
            .error()
        }

        const { questionSet } = payload

        const questionPages: Page[] = questionSet.pages || []
        const otherPages: OtherPage[] = questionSet.otherPages || []

        actions.setPages(questionPages)

        const {
          ErrorPage: errorPages,
          SuccessPage: successPages,
          RateSelectPage: ratesPages,
          RedirectInterstitial: interstitialPages,
        } = groupBy(otherPages, 'component')

        if (errorPages?.length) {
          actions.setErrorPages(errorPages as ErrorPage[])
        }

        if (successPages?.length) {
          actions.setSuccessPages(successPages as SuccessPage[])
        }

        if (ratesPages?.length) {
          actions.setRatesPage(ratesPages[0] as RateSelectPage)
        }

        if (interstitialPages?.length) {
          actions.setRedirectInterstitial(
            interstitialPages[0] as RedirectInterstitial
          )
        }

        const searchString = history?.location?.search
          .split('&')
          .filter((param) => !param.startsWith('n='))
          .join('&')

        actions.setSearchString(searchString ?? window?.location?.search)
      }
    ),

    answer: thunk(
      async ({ next }, payload: any, { getStoreActions, getStoreState }) => {
        const {
          application,
          answers: { setAnswers },
          authentication: { checkAuthentication },
        } = getStoreActions()

        const {
          application: { applicationId, coBorrowerId, applicationGuid },
          answers: {
            mergedAnswers: { firstName },
          },
        } = getStoreState()

        if (payload.creditScore) {
          payload.creditScore = Number(payload.creditScore)
        }

        if (payload.coBorrowerCreditScore) {
          payload.coBorrowerCreditScore = Number(payload.coBorrowerCreditScore)
        }

        if (!applicationId) {
          await application.initialize()
        }

        const { applicationId: newApplicationId } = getStoreState().application

        const primaryBorrowerSsn = payload.ssn
        const coBorrowerSsn = payload.coBorrowerSsn
        const createAccount = payload.createAccount

        if (primaryBorrowerSsn) {
          await ApplicationClient.saveSSNAlias(
            newApplicationId,
            primaryBorrowerSsn
          )

          // Save state that says we've succesfully saved an SSN
          // So we can skip the SSN page as the user navigates the app
          payload.hasSavedSSN = true

          delete payload.ssn // Prevent sending ssn in subsequent requests
        }

        if (coBorrowerSsn) {
          await ApplicationClient.saveCoBorrowerSSNAlias(
            newApplicationId,
            coBorrowerId,
            coBorrowerSsn
          )

          // Save state that says we've succesfully saved an SSN
          // So we can skip the SSN page as the user navigates the app
          payload.hasSavedCoBorrowerSSN = true

          delete payload.coBorrowerSsn // Prevent sending co borrower ssn in subsequent requests
        }

        const skipBorrowerPortal = await variation(
          FEATURE_FLAG_TYPES.SKIP_BORROWER_PORTAL
        )

        if (createAccount && !skipBorrowerPortal) {
          const isPasswordlessWorkflow = await variation(
            FEATURE_FLAG_TYPES.PASSWORDLESS_WORKFLOW
          )
          const accountInfoIndex = payload.createAccount.findIndex(
            (item) => item.email && (isPasswordlessWorkflow || item.password)
          )

          const { email, password } = payload.createAccount[accountInfoIndex]

          if (accountInfoIndex !== -1) {
            let response, auth0Id
            try {
              response = await ApplicationClient.createAuth0Account(
                email,
                password,
                firstName
              )
              auth0Id = response.data?.createAuth0Account?.auth0Id
            } catch (e) {
              rollbar.error(new CustomError('AuthError', e.message))
              throw new Error(
                'Oops, something went wrong creating your account. Try a different email address.'
              )
            }

            // set email answer here so we have it at the top level and don't need to ask again later
            payload.email = email

            if (response.errors) {
              if (
                response.errors[0].message === 'Error signing up user.' &&
                (environment.productName === 'sage' ||
                  environment.productName === 'sage-home-loans')
              ) {
                throw new CustomError(
                  'internallyThrownError',
                  `It looks like there’s already an account associated with that email. Please go and <a class="info-block__link" href="${environment.borrowerPortal.uiBaseUrl}/login-continue-application?continue-application-guid=${applicationGuid}">sign in</a>.`
                )
              } else if (
                response.errors[0].message === 'Error signing up user.' &&
                environment.productName === 'interest'
              ) {
                throw new Error(
                  'It looks like there’s already an account associated with that email. Please log in.'
                )
              } else {
                rollbar.error(
                  new CustomError('AuthError', response.errors[0].message)
                )
                throw new Error(
                  'Oops, something went wrong creating your account. Try a different email address.'
                )
              }
            }
            // Removes the user entered email and password from the create account array
            delete payload.createAccount[accountInfoIndex].email
            delete payload.createAccount[accountInfoIndex].password

            payload.auth0Id = auth0Id // setting to top level for visibleIf conditions on page level
            payload.createAccount[accountInfoIndex] = {
              ...payload.createAccount[accountInfoIndex],
            } // spread operator retains answers if storyblok has more questions in createAccount component

            checkAuthentication(true)
          }
        }

        // Set answers after deleting ssn (if ssn exists) and removing email/pass if create account page
        setAnswers(payload)

        await getStoreActions().application.save(payload)
      }
    ),

    getAdjacentPageIndex: thunk(
      (actions, increment, { getState, getStoreState }) => {
        const { pageIndex, pages } = getState()
        const { answers, visibleIfConditions } = getStoreState()

        const nextPageIndex = pageIndex + increment
        const nextPage = pages[nextPageIndex]

        if (nextPage === undefined) {
          return false
        }

        if (increment < 0 && isApiLoaderPage(pages, nextPageIndex)) {
          return actions.getAdjacentPageIndex(increment + Math.sign(increment))
        }

        if (
          isVisibleIfConstructor(nextPage.visibleIfConstructor) &&
          !isQuestionVisible(
            nextPage.visibleIfConstructor[0],
            answers.mergedAnswers,
            visibleIfConditions
          )
        ) {
          return actions.getAdjacentPageIndex(increment + Math.sign(increment))
        }

        if (
          nextPage.visibleIf &&
          !isQuestionVisible(
            nextPage.visibleIf,
            answers.mergedAnswers,
            visibleIfConditions
          )
        ) {
          return actions.getAdjacentPageIndex(increment + Math.sign(increment))
        }

        return nextPageIndex
      }
    ),

    next: thunk(
      async (actions, __, { getState, getStoreActions, getStoreState }) => {
        const { active, searchString } = getState()
        const { pageSections, tracking } = getStoreActions()
        const { isMiniFlow } = getStoreState().questionnaire

        tracking.formContinued()

        if (active === null) {
          return
        }

        if (isMiniFlow) {
          window.parent.postMessage(
            {
              name: MessageTypes.MiniFlowTaskCompleted,
            },
            '*'
          )

          return
        }

        const nextPageIndex = actions.getAdjacentPageIndex(1)

        if (nextPageIndex) {
          actions.setPageIndex(nextPageIndex)

          history.push({
            search: `${searchString ? searchString : '?'}&n=${nextPageIndex}`,
          })
        } else {
          actions.setFinished(true)
        }

        const previousSection = active.section
        pageSections.updateSectionStatuses(previousSection)
      }
    ),

    // Not used in NTL flow - already have credit score and this will override statuses
    submit: thunk(
      async ({ setIsSubmitting }, __, { getStoreState, getStoreActions }) => {
        const { mergedAnswers } = getStoreState().answers
        const { statusesMap } = getStoreState().questionnaire
        const { softPullCredit, updateStatus } = getStoreActions().application

        setIsSubmitting(true)

        // If a credit score doesn't already exist on state, run soft credit pull
        if (!mergedAnswers.creditScore) {
          await softPullCredit()
        }

        await updateStatus(statusesMap.completed)

        setIsSubmitting(false)
        return
      }
    ),

    back: thunk((actions, _, { getState, getStoreActions }) => {
      const { pageSections } = getStoreActions()
      const { pages, pageIndex, searchString } = getState()

      const previousPageIndex = actions.getAdjacentPageIndex(-1)

      if (previousPageIndex !== pageIndex) {
        actions.setPageIndex(previousPageIndex)

        if (typeof previousPageIndex === 'number') {
          history.push({
            search: `${
              searchString ? searchString : '?'
            }&n=${previousPageIndex}`,
          })
        }

        pageSections.setSectionStatus({
          sectionName: pages[previousPageIndex]?.section,
          statusCode: SectionStatusCode.active,
        })
      }
    }),

    handlePhoneAppRedirect: thunkOn(
      (actions, storeActions) => [
        actions.setSlug,
        storeActions.answers.setAnswers,
      ],
      async (actions, target, { getState, getStoreState }) => {
        const { loanPurpose } = getStoreState().answers.answers
        const { slug } = getState()

        if (slug && loanPurpose) {
          try {
            // Redirect phone app users to apply flow if FF is off
            const isPhoneFlow = getStoreState().questionnaire.isPhoneFlow
            const isPhoneFlowEnabled = await variation(
              FEATURE_FLAG_TYPES.PHONE_FLOW_ENABLED
            )

            if (isPhoneFlow && !isPhoneFlowEnabled) {
              return redirectToOtherQuestionSet(
                loanPurpose === LoanPurpose.Purchase
                  ? QuestionSetSlug.ApplyPurchase
                  : QuestionSetSlug.ApplyRefinance
              )
            }
          } catch (e) {
            const { applicationGuid } = getStoreState().application

            incense(e)
              .details({
                name: 'handlePhoneAppRedirectError',
                message: 'Error during redirect for phone app',
              })
              .sensitive({
                applicationGuid,
                slug,
                loanPurpose,
              })
              .error()

            redirectTo(`${Routes.APP_1003}/error`)
          }
        }
      }
    ),

    handleInitialPage: thunkOn(
      (actions, storeActions) => [
        storeActions.answers.setIsPrefillingAnswers,
        actions.setPages,
      ],
      async (actions, target, { getState, getStoreState, getStoreActions }) => {
        const state = getState()
        const { visibleIfConditions, answers, application } = getStoreState()
        const { pageSections, tracking } = getStoreActions()

        const { pages, bankrateLeadId } = state
        const { applicationGuid } = application
        const { isPrefillingAnswers, mergedAnswers } = answers

        // If we're not prefilling, questionnaire pages have been loaded, and no active page... then compute initial active page based on visibleIfs
        if (isPrefillingAnswers === false && pages?.length && !state.active) {
          let initialPageIndex = pages.findIndex((page) => {
            const visibleIfCondition = page.visibleIfConstructor?.[0]

            const pageIsVisible =
              !visibleIfCondition ||
              isQuestionVisible(
                visibleIfCondition,
                mergedAnswers,
                visibleIfConditions
              )

            return pageIsVisible
          })

          initialPageIndex = initialPageIndex >= 0 ? initialPageIndex : 0

          actions.setPageIndex(initialPageIndex)
          pageSections.initializeSectionStatuses(getState().active?.section)

          try {
            tracking.formStarted()

            const attrs = [
              { key: 'from_bankrate', value: (!!bankrateLeadId).toString() },
              {
                key: 'is_iframe',
                value: (window.location !== window.parent?.location).toString(),
              },
            ]
            newRelic.increment('form_started', attrs)
          } catch (error) {
            const errorObject = new CustomError(
              'GetQuestionnaireError',
              'Failed to apply tracking and metrics'
            )

            const errorMetadata = {
              bankrateLeadId,
              applicationGuid,
              sensitive: { error, mergedAnswers },
            }

            rollbar.error(errorObject, errorMetadata)

            newRelic.increment('get_questionnaire.fail', [
              { key: 'from_bankrate', value: (!!bankrateLeadId).toString() },
            ])

            throw error
          }
        }
      }
    ),
  }
}

export default questionnaire

export const questionnaireQuery = getGraphQLString(gql`
  fragment VisibleIfSingleConditionFragment on VisibleIfSingleCondition {
    question
    answer
    conditional
  }

  fragment QuestionBaseFragment on QuestionBase {
    title
    subTitle
    questionName {
      name
    }
    infoTitle
    infoContent
    infoWarning
    content
    visibleIf
    component
    modifierClass
    visibleIfConstructor {
      ... on VisibleIfPrebuiltCondition {
        name
      }
      ... on VisibleIfSingleCondition {
        ...VisibleIfSingleConditionFragment
      }
      ... on VisibleIfMultiCondition {
        operator
        conditions {
          ... on VisibleIfSingleCondition {
            ...VisibleIfSingleConditionFragment
          }
          ... on VisibleIfMultiCondition {
            operator
            conditions {
              ... on VisibleIfSingleCondition {
                ...VisibleIfSingleConditionFragment
              }
            }
          }
        }
      }
    }
    validations {
      validatorType
      rules {
        name
        params
      }
      condition {
        reference
        is
      }
    }
  }

  fragment OptionFragment on Option {
    text
    value
    inputField {
      ...QuestionBaseFragment
    }
    subOptions {
      ...QuestionBaseFragment
      defaultOption
      multiAnswerEnabled
      modifierClass
      options {
        text
        value
        inputField {
          ...QuestionBaseFragment
        }
      }
    }
  }

  fragment QuestionSwitch on Questions {
    ... on QuestionHidden {
      ...QuestionBaseFragment
      value
    }
    ... on QuestionToggle {
      ...QuestionBaseFragment
      toggled
    }
    ... on QuestionCheckbox {
      ...QuestionBaseFragment
      checked
      modifierClass
    }
    ... on QuestionCheckboxMulti {
      ...QuestionBaseFragment
      defaultOption
      multiAnswerEnabled
      modifierClass
      bottomContent
      content
      options {
        ...OptionFragment
      }
    }
    ... on QuestionSelect {
      ...QuestionBaseFragment
      modifierClass
      options {
        text
        value
      }
    }
    ... on QuestionMultiSelectButton {
      ...QuestionBaseFragment
      modifierClass
      options {
        ...OptionFragment
        iconName
      }
    }
    ... on QuestionStateSelect {
      ...QuestionBaseFragment
      modifierClass
    }
    ... on QuestionRadio {
      ...QuestionBaseFragment
      modifierClass
      options {
        text
        value
      }
      defaultValue
    }
    ... on QuestionBinaryButton {
      ...QuestionBaseFragment
      defaultYes
    }
    ... on QuestionMultiButton {
      ...QuestionBaseFragment
      options {
        text
        value
      }
    }
    ... on QuestionIncome {
      ...QuestionBaseFragment
      options {
        text
        value
      }
    }

    ... on QuestionInput {
      ...QuestionBaseFragment
      modifierClass
      inputType
      maxLength
      placeholder
    }
    ... on QuestionAddressAutoComplete {
      ...QuestionBaseFragment
      maxLength
      placeholder
    }
    ... on QuestionAddressFieldsAutoComplete {
      ...QuestionBaseFragment
      maxLength
      placeholder
    }
    ... on QuestionRateSelection {
      ...QuestionBaseFragment
      modifierClass
    }
    ... on QuestionNTLRateSelection {
      ...QuestionBaseFragment
      modifierClass
    }
    ... on QuestionCompareLERateSelection {
      ...QuestionBaseFragment
      modifierClass
      redirectToSlug
    }
    ... on QuestionCreateAccount {
      ...QuestionBaseFragment
    }
    ... on QuestionAPICallLoader {
      ...QuestionBaseFragment
      apiCall
      minimumTimeout
      errorMessage
      continueOnError
    }
    ... on QuestionUploader {
      ...QuestionBaseFragment
      topSubHeader
      uploadButtonText
      uploadErrorText
      documentType
    }
    ... on QuestionInfoAccordion {
      ...QuestionBaseFragment
      accordionContent
    }
  }

  query getQuestionnaire(
    $id: String!
    $bankrateLeadId: String
    $applicationGuid: String
    $directMailerOfferCode: String
    $hostName: String
  ) {
    getAnswers(
      bankrateLeadId: $bankrateLeadId
      applicationGuid: $applicationGuid
      directMailerOfferCode: $directMailerOfferCode
    ) {
      bankrateAnswers {
        id
        firstName
        lastName
        street
        city
        state
        zipCode
        email
        phone
        cashOut
        isTakingCashOut
        propertyType
        propertyUse
        loanPurpose
        estimatedValue
        remainingBalance
        currentMortgageBalance
        currentMortgageRate
        previouslySelectedRate {
          rate
          termInMonths
          loanAmount
        }
        propertyPurchasePrice
        propertyDownPayment
        declarationMilitary
        totalAssets
        grossAnnualIncome
        monthlyDebtsAmount
        propertyState
        propertyZip
        propertyCity
      }
      applicationAnswers {
        applicationId
        firstName
        lastName
        phone
        street
        apt
        city
        state
        zipCode
        propertyUse
        propertyType
        propertyAttachment
        propertyNumberOfStories
        loanPurpose
        estimatedValue
        remainingBalance
        cashOut
        isTakingCashOut
        propertyPurchasePrice
        propertyDownPayment
        purchaseStage
        purchaseKnownAddress
        autoCompleteAddressFields
      }
      directMailerAnswers {
        firstName
        lastName
        street
        city
        state
        zipCode
      }
    }

    getQuestionnaire(id: $id, hostName: $hostName) {
      flowId
      otherPages {
        ... on RedirectInterstitial {
          component
          title
          body
          time
        }
        ... on ErrorPage {
          component
          name
          title
          body
          primaryCtaTitle
          primaryCtaLink {
            url
          }
        }
        ... on SuccessPage {
          component
          name
          title
          subtitle
          body
          primaryCtaTitle
          primaryCtaLink {
            url
          }
        }
        ... on RateSelectPage {
          component
          variations {
            variation
            title
            subTitle
            cta
          }
        }
      }
      pages {
        name
        section
        type
        title
        subTitle
        description
        visibleIf
        visibleIfConstructor {
          ... on VisibleIfPrebuiltCondition {
            name
          }
          ... on VisibleIfSingleCondition {
            ...VisibleIfSingleConditionFragment
          }
          ... on VisibleIfMultiCondition {
            operator
            conditions {
              ... on VisibleIfSingleCondition {
                ...VisibleIfSingleConditionFragment
              }
              ... on VisibleIfMultiCondition {
                operator
                conditions {
                  ... on VisibleIfSingleCondition {
                    ...VisibleIfSingleConditionFragment
                  }
                }
              }
            }
          }
        }
        displaySumOfFields
        nextButtonText
        allowSkip
        modifierClass
        customPageValidation
        validationErrorMsg
        questions {
          ...QuestionSwitch
          ... on QuestionGroup {
            ...QuestionBaseFragment
            titleForRepeatButton
            textForRepeatButton
            textForDeleteButton
            autoRepeatValidationKey
            questions {
              ...QuestionSwitch
            }
          }
          ... on QuestionCreateAccount {
            ...QuestionBaseFragment
            questions {
              ...QuestionSwitch
            }
          }
        }
      }
    }
  }
`)

async function requestQuestionnaire(
  id: string,
  bankrateLeadId?: string,
  applicationGuid?: string,
  directMailerOfferCode?: string
) {
  return await graphQL({
    query: questionnaireQuery,
    variables: {
      id,
      bankrateLeadId,
      applicationGuid,
      directMailerOfferCode,
      hostName: window.location.hostname,
    },
  })
}

function isQuestionSet(pattern: string | RegExp, slug: string) {
  const regex = new RegExp(pattern)
  return slug && regex.test(slug.toLowerCase())
}

function isApiLoaderPage(pages, pageIndex) {
  if (!pages?.length || !pageIndex) {
    return false
  }

  return pages?.[pageIndex]?.questions?.find(
    (question) => question?.questionName?.name === 'apiLoader'
  )
}
